import { matchPath } from "react-router-dom";
import GenericPage from "../../components/GenericPage";
import GenericNews from "../../components/GenericNews";
import Home from "../../components/Home";
import store from "../../store";

export const ENVIRONMENT = process.env.NODE_ENV === "production" ? "production" : "development";

export const PageComponents = {};
PageComponents.PrivacyPolicy = require( "./components/PrivacyPolicy" ).default;
PageComponents.CookiesPolicy = require( "./components/CookiesPolicy" ).default;

export const getUrlBase = ( env ) => {
    if ( env === "development" ) {
        return "http://localhost:2048";
    }
    return "https://www.chihuahuasycanichestoy.com";
};

export const company = {
    name: "Son Genet",
    legalName: "Son Genet",
    domain: "chihuahuasycanichestoy.com",
    lopdFullName: "Son Genet",
    lopdNIF: "-",
    lopdEmail: "-",
    lopdPostalAddress: "",
    lopdPhone: "",
    facebookPageURL: "https://www.facebook.com/songenet",
    whatsappContactURL: "https://wa.me/34676547960",
    googleFontsTag: "<link href=\"https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap\" rel=\"stylesheet\" />",
    adobeFontsTag: "<link rel=\"stylesheet\" href=\"https://use.typekit.net/uwb6htm.css\">",
    googleAnalyticsTrackingID: "UA-143522464-9",
    facebookAPPID: "348655195810991",
    contactEmail: "info@chihuahuasycanichestoy.com",
    managerURLRelative: "/songenetadmin",
    googleSignInClientID: "751791303968-57gt7p9jpj3hlfss9vgu0rfjea9f22of.apps.googleusercontent.com",
    baseURL: ENVIRONMENT === "development" ? "http://localhost:2048" : "https://www.chihuahuasycanichestoy.com",
    footerHideLink: true,
    footerHideFacebook: true,
    footerHideContactButtons: true,
    hideFooterForm: true,
    hideFooterTitle: true
};

export const stripe = null;
export const gtag = null;

export const floatingActionButtons = [
    {
        exactUrl: true,
        relativeUrl: "/",
        href: company.whatsappContactURL,
        src: "/static/whatsapp-icon-fab.png",
        displayScrollY: 0
    }
];
export const floatingVideos = null;

export const config = {
    reCaptchaSiteKey: "6LeqmKoUAAAAAPBWBD8aQmVM9XIc4qNQgsjGHdFJ"
};

export const NavBar = {
    topNavBar: {
        enabled: false
    },
    customMenuItems: [
        {
            id: "c1",
            menuText: "CHIHUAHUAS",
            menuItems: [
                { id: "c1-01", menuText: "EL CHIHUAHUA Y SU CARÁCTER", relativeUrl: "/novedades-chihuahuas-caniches/chihuahuas/el-chihuahua-caracter" },
                { id: "c1-02", menuText: "CUIDADOS DEL CHIHUAHUA", relativeUrl: "/novedades-chihuahuas-caniches/chihuahuas/cuidados-del-chihuahua" },
                { id: "c1-03", menuText: "EL ESTÁNDAR DEL CHIHUAHUA", relativeUrl: "/novedades-chihuahuas-caniches/chihuahuas/el-chihuahua-estandar-fci" },
                { id: "c1-04", menuText: "NUESTROS CHIHUAHUAS", relativeUrl: "/novedades-chihuahuas-caniches/chihuahuas/nuestros-chihuahuas" },
                { id: "c1-05", menuText: "NUESTROS CACHORROS", relativeUrl: "/novedades-chihuahuas-caniches/chihuahuas/nuestros-cachorros" },
                { id: "c1-06", menuText: "CACHORROS DISPONIBLES", relativeUrl: "/p/chihuahuas-cachorros-disponibles" }
            ]
        },
        {
            id: "c0",
            menuText: "CANICHES TOY",
            menuItems: [
                { id: "c0-01", menuText: "EL CANICHE TOY Y SU CARÁCTER", relativeUrl: "/novedades-chihuahuas-caniches/caniches/el-caniche-caracter" },
                { id: "c0-02", menuText: "CUIDADOS DEL CANICHE TOY", relativeUrl: "/novedades-chihuahuas-caniches/caniches/cuidados-del-caniche" },
                { id: "c0-03", menuText: "EL ESTÁNDAR DEL CANICHE TOY", relativeUrl: "/novedades-chihuahuas-caniches/caniches/el-caniche-estandar-fci" },
                { id: "c0-04", menuText: "NUESTROS CANICHES TOY", relativeUrl: "/novedades-chihuahuas-caniches/caniches/nuestros-caniches" },
                { id: "c0-05", menuText: "CACHORROS DISPONIBLES", relativeUrl: "/p/caniches-cachorros-disponibles" }
            ]
        },
        {
            id: "c3",
            menuText: "FOTOS EN CASA",
            relativeUrl: "/p/fotos-en-casa"
        },
        {
            id: "c4",
            menuText: "ENTREGADOS",
            relativeUrl: "/p/entregados"
        },
        {
            id: "c2",
            menuText: "PREGUNTAS FRECUENTES",
            menuItems: [
                { id: "c2-01", menuText: "CONDICIONES DE ENTREGA", relativeUrl: "/novedades-chihuahuas-caniches/condiciones-entrega" },
                { id: "c2-02", menuText: "¿CÓMO RESERVAR?", relativeUrl: "/novedades-chihuahuas-caniches/acerca-son-genet/reservas" },
                { id: "c2-03", menuText: "COMPROMISO", relativeUrl: "/p/faq-compromiso" },
                { id: "c2-04", menuText: "OTRAS PREGUNTAS FRECUENTES", relativeUrl: "/novedades-chihuahuas-caniches/acerca-son-genet/preguntas-frecuentes" }
            ]
        }
    ]
};

export const all = [
    {
        path: "(/|/en/|/es/)",
        relativeUrl: "/",
        relativeUrlEn: "/en",
        url: "https://www.chihuahuasycanichestoy.com",
        component: Home,
        exact: true,
        id: "home",
        menuText: "Inicio",
        menuTextEn: "Home",
        menuHidden: true,
        title: "Inicio",
        titleEn: "Home",
        subTitle: "Son Genet - Chihuahuas en Mallorca | Caniches toy en Mallorca | Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        breadcrumb: [],
        seoTitle: "Son Genet - Chihuahuas en Mallorca | Caniches toy en Mallorca | Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        seoDescription: "Nuestros Chihuahuas y Caniches Toy son una parte esencial de nuestras vidas. Comparten nuestro hogar y nuestro tiempo. Ellos son nuestra prioridad y nuestra pasión, tienen los mejores cuidados, los mejores piensos y la mejor atención veterinaria.",
        seoKeywords: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca. Llámanos o visita nuestra web si buscas Chihuahuas o Caniches Toy en Mallorca criados con mucho cariño y en excelentes condiciones",
        seoAuthor: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        seoDefaultAlt: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        embeds: [],
        gallery: {
            id: "generic"
        }
    },
    {
        path: "(/novedades-chihuahuas-caniches.*)",
        relativeUrl: "/novedades-chihuahuas-caniches",
        url: "https://www.chihuahuasycanichestoy.com/novedades-chihuahuas-caniches",
        exact: true,
        component: GenericNews,
        childComponentFile: "News",
        id: "news",
        menuText: "Novedades",
        menuTextEn: "News",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Son Genet - Chihuahuas en Mallorca | Caniches toy en Mallorca | Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        seoDescription: "Nuestros Chihuahuas y Caniches Toy son una parte esencial de nuestras vidas. Comparten nuestro hogar y nuestro tiempo. Ellos son nuestra prioridad y nuestra pasión, tienen los mejores cuidados, los mejores piensos y la mejor atención veterinaria.",
        seoKeywords: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca. Llámanos o visita nuestra web si buscas Chihuahuas o Caniches Toy en Mallorca criados con mucho cariño y en excelentes condiciones",
        seoAuthor: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        seoDefaultAlt: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        embeds: [],
        gallery: {},
        navBarCSSPosition: "fixed",
        navBarPagePadding: 75
    },
    {
        path: "/chihuahuas-caniches-toy/politica-privacidad",
        relativeUrl: "/chihuahuas-caniches-toy/politica-privacidad",
        url: "https://www.chihuahuasycanichestoy.com/chihuahuas-caniches-toy/politica-privacidad",
        exact: true,
        component: GenericPage,
        childComponentFile: "PrivacyPolicy",
        id: "privacy-policy",
        menuText: "Política de privacidad",
        menuTextEn: "Privacy Policy",
        menuHidden: true,
        title: "Política de privacidad",
        subTitle: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        breadcrumb: [ "home", "privacy-policy" ],
        seoTitle: "Política de privacidad",
        seoDescription: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        seoKeywords: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/chihuahuas-caniches-toy/politica-cookies",
        relativeUrl: "/chihuahuas-caniches-toy/politica-cookies",
        url: "https://www.chihuahuasycanichestoy.com/chihuahuas-caniches-toy/politica-cookies",
        exact: true,
        component: GenericPage,
        childComponentFile: "CookiesPolicy",
        id: "cookies-policy",
        menuText: "Política de Cookies",
        menuTextEn: "Cookies Policy",
        menuHidden: true,
        title: "Política de Cookies",
        subTitle: "",
        breadcrumb: [ "home", "cookies-policy" ],
        seoTitle: "",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "(/p/.*)",
        relativeUrl: "/p",
        url: "https://www.chihuahuasycanichestoy.com/p",
        component: GenericPage,
        id: "pages",
        menuText: "",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        embeds: [],
        seoTitle: "Son Genet - Novedades en Chihuahuas en Mallorca | Caniches toy en Mallorca | Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        seoDescription: "Novedades de Nuestros Chihuahuas y Caniches Toy son una parte esencial de nuestras vidas. Comparten nuestro hogar y nuestro tiempo. Ellos son nuestra prioridad y nuestra pasión, tienen los mejores cuidados, los mejores piensos y la mejor atención veterinaria.",
        seoKeywords: "Novedades de Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca. Llámanos o visita nuestra web si buscas Chihuahuas o Caniches Toy en Mallorca criados con mucho cariño y en excelentes condiciones",
        seoAuthor: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca.",
        seoDefaultAlt: "Son Genet - Criadores de Chihuahuas y Caniches Toy para exposición y compañía en Mallorca."
    }
];
const getPagePropByLang = ( page, lang, prop ) => {
    const langProp = `${ prop }${ lang.charAt( 0 ).toUpperCase() + lang.slice( 1 ) }`;
    if ( typeof page[ langProp ] !== "undefined" ) {
        return page[ langProp ];
    }
    return page[ prop ];
};

const applyPageOverwrites = ( page ) => {
    let result = page;
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            result = Object.assign( {}, page, { title: getPagePropByLang( page, lang, "title" ) } );
            Object.keys( page ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( page, lang, key );
            } );
        }
    }
    return result;
};

export const getPage = ( id ) => {
    let result = all.find( ( page ) => page.id === id );
    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

const texts = {
    es: {
        "navbar.title": "",
        "footer.contact.text1": "¿Quieres conocer más acerca de nosotros?",
        "footer.contact.text2": "Completa el siguiente formulario para obtener más información.",
        "footer.contact.fullname": "Nombre Completo",
        "footer.contact.fullname.description": "",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "",
        "footer.contact.phone": "Teléfono",
        "footer.contact.phone.description": "",
        "footer.contact.message": "Mensaje",
        "footer.contact.privacypolicy": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "footer.contact.sendmessage": "Enviar consulta",
        "footer.contact.orremember": "O también puedes contactarnos a través de",
        "footer.contact.emailbutton": "Correo electrónico",
        "footer.contact.whatsapp": "Enviar un WhatsApp",
        "footer.contact.visitfacebook": "Visítanos en Facebook",
        "footer.contact.successmessage.text1": "Gracias por ponerte en contacto con nosotros.",
        "footer.contact.successmessage.text2": "Hemos recibido tu mensaje y te responderemos a la brevedad a",
        "footer.contact.successmessage.text3": "En {companyName} nos tomamos muy en serio las consultas de nuestros clientes y en responder profesionalmente. Muchas gracias por su paciencia.",
        "footer.copyright.text1": "Copyright Son Genet - Criadores de chihuahuas y caniches toy en Mallorca",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más"
        // customs
    },
    en: {
        "navbar.title": "",
        "footer.contact.text1": "Any further questions?",
        "footer.contact.text2": "Please do not hesitate to contact us, tell us about your business or project and We will reply as soon as possible.",
        "footer.contact.fullname": "Full name",
        "footer.contact.fullname.description": "Please enter your full name",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Enter your email to reply as soon as possible.",
        "footer.contact.phone": "Phone",
        "footer.contact.phone.description": "Enter your phone and we can contact you via Whatsapp or a call.",
        "footer.contact.message": "Message",
        "footer.contact.privacypolicy": `I have read and accept the <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and the <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "footer.contact.sendmessage": "Send message",
        "footer.contact.orremember": "Or remember you can also send us a message via",
        "footer.contact.emailbutton": "Email",
        "footer.contact.whatsapp": "Send WhatsApp",
        "footer.contact.visitfacebook": "Visit us on Facebook",
        "footer.contact.successmessage.text1": "Thank you to contact us",
        "footer.contact.successmessage.text2": "Your message has been successfully sent and we´ll reply you as soon as possible.",
        "footer.contact.successmessage.text3": "At {companyName} we work hard to reply our customers messages in a professional way. Thank you for your patience. ",
        "footer.copyright.text1": "Copyright Son Genet - Criadores de chihuahuas y caniches toy en Mallorca",
        // news
        "news.categories": "Categories",
        "news.viewmore": "View more"
        // customs
    }
};

export const defaultLanguage = "es";
export const availableLanguages = [ "es", "en" ];

// GENERIC
export const getPageByPath = ( path ) => {
    let result = all.find( ( page ) => {
        if ( page.path === path ) {
            return page;
        }
        if ( matchPath( path, page ) ) {
            return page;
        }
        return null;
    } );

    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

export const manager = {};
manager.getRelativeUrl = ( relative ) => `${ company.managerURLRelative }/${ relative }`;

export const text = ( lang, id, replacements ) => {
    let result = "";
    if ( texts[ lang ] && texts[ lang ][ id ] ) {
        result = texts[ lang ][ id ];
    }
    if ( typeof replacements !== "undefined" ) {
        Object.keys( replacements ).forEach( key => {
            result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
        } );
    }
    return result;
};

export const getLanguageName = ( lang ) => {
    switch ( lang ) {
        case "en":
            return "English";
        case "es":
            return "Español";
        default:
            return "Español";
    }
};

export const getLangByUrlPath = ( path ) => {
    let result = defaultLanguage;
    availableLanguages.forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

export const applyNewsOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};
export const applyShopOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};
export const contactForms = null;

export const emailTemplates = {
    theme: {
        logoBackgroundColor: "white",
        logoUrl: `${ company.baseURL }/static/logo-rectangle.png`,
        thanksBackgroundColor: "#F8F8F8",
        buttonBackgroundColor: "#F8F8F8",
        buttonTextColor: "black",
        purchaseDetailsUrl: `${ company.baseURL }/shop/purchase`,
        defaultFont: "Arial",
        defaultFontColor: "#333333",
        purchaseThanksTitle: "Muchas gracias por su compra",
        purchaseThanksSubTitle: "Por favor, conserve la siguiente información.",
        footerText: `Para cualquier información no dude en contactarnos a través de nuestra web ${ company.baseURL }`
    }
};
