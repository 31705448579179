/* eslint-disable max-len */
const htmlContent = {};
htmlContent.content001 = "<h2>Machito de excelente pedigree</h2><p>Papá Ruso azul de pelo largo</p><p>Excelente pedigree</p><p>Tiene 12 semanas. Lista para entregar con 2 vacunas, pasaporte y desparasitada</p><b>Excelente caracter!!</b><p>No apta para cria por su tamaño</p>";
export default {
    generic: {
        title: "Nuestros Chihuahuas y Caniches Toy",
        subTitle: "",
        photos: [
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-001.jpg",
                width: 2,
                height: 1.5,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            },
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-002.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            },
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-003.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            },
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-004.jpg",
                width: 2,
                height: 1.5,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            },
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-005.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            },
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-006.jpg",
                width: 4,
                height: 3,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            },
            {
                src: "/static/chihuahuas-caniches-toy-mallorca-007.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas y Caniches Toy en Mallorca"
            }
        ]
    },
    entregados: {
        title: "",
        subTitle: "",
        custom: true,
        photos: [
            {
                src: "/static/entregados-1c2d6332-99f9-46a2-ae3a-9a48e218b31b.jpeg",
                width: 1,
                height: 0.76708984375,
                alt: "Entregados"
            },
            {
                src: "/static/entregados-cf5f3f3b-34fb-4e7b-ba33-f86e4b3349cd.jpeg",
                width: 1,
                height: 0.716796875,
                alt: "Entregados"
            },
            {
                src: "/static/entregados-af49c0ac-19ec-4bed-b35e-edf710b68807.jpg",
                width: 1,
                height: 1.0183696900114811,
                alt: "Chihuahuas y Caniches Toy en sus nuevas casas",
                overlay: { title: "Jackie feliz con su familia de La Alberca" }
            },
            {
                src: "/static/entregados-77c7c47b-0baf-483a-8e93-e3c8fad70b5a.jpg",
                width: 1,
                height: 0.75,
                alt: "Chihuahuas y Caniches Toy en sus nuevas casas",
                overlay: { title: "Luuk en su nuevo hogar de Tarragona" }
            },
            {
                src: "/static/entregados-569cd253-ac20-4735-8b2f-27a6a43ed78b.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Chihuahuas y Caniches Toy en sus nuevas casas"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-009.jpeg",
                width: 1,
                height: 1.33,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-010.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-012.jpeg",
                width: 1,
                height: 1.33,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-013.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-90756545-b59d-41fd-a007-e14a06410658.jpg",
                width: 1,
                height: 0.9708333333333333,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-8fd22ca4-4cc1-488a-9036-e09ba8a9ac65.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-d2969cf9-c860-41ba-8223-7564e0c45796.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-1ea2cd4f-d628-439f-8b21-9b9d0b9b4191.jpg",
                width: 1,
                height: 0.563125,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-0d504b04-f6ad-43cb-aff1-30320395835b.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-f2ae1837-1d10-4629-8650-34c227ce8c8d.jpg",
                width: 1,
                height: 1.0700778642936597,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-016fecd3-aca6-470f-8294-85b5a1506229.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-b6811a4f-4c8c-44af-bfe7-58d4cd1c9eae.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-chihuahua-47998c9b-19c8-420f-add6-b0031df8d9c0.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/fotosencasa-2abbb764-29c2-4532-ab02-ead844900c6c.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Chihuahuas en sus nuevas casas"
            },
            {
                src: "/static/nuestros-chihuahuas-002.jpeg",
                width: 2,
                height: 2.66,
                alt: "Son Genet: Chihuahuas en Mallorca"
            },
            {
                src: "/static/chihuahuas-disponibles-001-1.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca"
            }
        ]
    },
    chihuahuasdisponibles: {
        title: "",
        subTitle: "",
        direction: "column",
        custom: true,
        photos: [
            {
                src: "/static/chihua-disponibles-2021-001.jpeg",
                width: 1,
                height: 2.1136,
                alt: "Chihuahuas y Caniches Toy",
                overlay: { title: "Hembra pelo largo" }
            },
            {
                src: "/static/chihua-disponibles-2021-002.jpeg",
                width: 1,
                height: 0.6437,
                alt: "Chihuahuas y caniches toy",
                overlay: { title: "Hembra pelo corto" }
            },
            {
                src: "/static/chihua-disponibles-2021-003.jpeg",
                width: 1,
                height: 0.7249,
                alt: "Chihuahuas y caniches toy",
                overlay: { title: "Macho pelo largo" }
            }
        ]
    },
    canichesdisponibles: {
        title: "",
        subTitle: "",
        custom: true,
        photos: []
    },
    nuestroscachorroschihuahuas: {
        title: "",
        subTitle: "",
        custom: true,
        photos: [
            {
                src: "/static/nuestroscachorroschihuahuas-2021-10-18-001.jpeg",
                width: 1,
                height: 0.473,
                alt: "Son Genet | Cachorros Chihuahuas en Mallorca"
            }
        ]
    },
    nuestroschihuahuas: {
        title: "",
        subTitle: "",
        custom: true,
        photos: [
            {
                src: "/static/nuestroschihuahuas-b36e6c61-82dd-42cd-9e47-6d9907f6774d.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet | Chihuahuas en Mallorca"
            },
            {
                src: "/static/nuestroschihuahuas-72c23cc4-e433-4c9f-8a49-9b54ed9daad5.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca"
            },
            {
                src: "/static/nuestroschihuahuas-db9eada7-3d42-4d49-b50d-819957f4fdd7.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca"
            },
            {
                src: "/static/nuestroschihuahuas-a0a645b6-aaf5-4a43-b9ca-66006e989a55.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca",
                overlay: {
                    title: "Anna de Son Genet"
                }
            },
            {
                src: "/static/nuestroschihuahuas-b0a79dc4-18cb-462c-b360-62839153b505.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca",
                overlay: {
                    title: "Clara"
                }
            },
            {
                src: "/static/nuestroschihuahuas-e74dd7ed-26f8-4d3d-a5ab-5e24efc8baf9.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca",
                overlay: {
                    title: "Mika del Cordobita"
                }
            },
            {
                src: "/static/nuestroschihuahuas-7048f1e3-fbc5-4476-a2bb-008add8e3007.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet | Chihuahuas en Mallorca",
                overlay: {
                    title: "Tina de Fuente Blanca"
                }
            },
            {
                src: "/static/nuestroschihuahuas-d6957298-a853-40e3-94e2-9036502f24d4.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca",
                overlay: {
                    title: "Mika del Cordobita"
                }
            },
            {
                src: "/static/nuestroschihuahuas-d65caded-b5d4-46d4-8f18-1349334103e4.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca",
                overlay: {
                    title: "Naia de Reyes del nueve"
                }
            },
            {
                src: "/static/nuestroschihuahuas-371ee680-6fee-4ea8-8632-5d6f5e16674f.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet | Chihuahuas en Mallorca"
            },
            {
                src: "/static/nuestros-chihuahuas-heloise-001.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: { title: "Heloise de la reina de oros" }
            },
            {
                src: "/static/nuestros-chihuahuas-rafaela-barca-001.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: { title: "Rafaela de la reina de oros" }
            },
            {
                src: "/static/nuestroschihuahuas-5966eaa8-c4ee-4eb0-8a0a-6ec996d5b57e.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: { title: "Cosmo de la Reina de oros" }
            },
            {
                src: "/static/nuestros-chihuahuas-009.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Lady gaga de reyes del 9"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-003.jpeg",
                width: 1,
                height: 1.34,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Clara"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-004.jpeg",
                width: 2,
                height: 2.66,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Genma"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-005.jpeg",
                width: 2,
                height: 2.66,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Shyla"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-006.jpg",
                width: 1,
                height: 1.33,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Mara de Son Genet"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-007.jpg",
                width: 1,
                height: 0.72,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Rafaela de la reina de oros"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-008.jpg",
                width: 1,
                height: 1.33,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Pandora de puro axe"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-001.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Tina de fuenteblanca"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-010.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Edgar de estrellas del zaffiro"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-011.jpeg",
                width: 1,
                height: 0.56,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Cosmo de la reina de oros"
                }
            },
            {
                src: "/static/nuestros-chihuahuas-012.jpeg",
                width: 1,
                height: 1.33,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Naia de los reyes del 9"
                }
            },
            {
                src: "/static/canichesdisponibles-cb415d09-6192-43d4-92cc-275b3ed7cffb.jpeg",
                width: 1,
                height: 1,
                alt: "Caniches disponibles"
            }
        ]
    },
    nuestroscaniches: {
        title: "",
        subTitle: "",
        custom: true,
        direction: "column",
        photos: [
            {
                src: "/static/nuestros-caniches-4618f7a0-fb6d-455d-950b-01e36ff8d45e.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-520a0232-9b34-4544-881d-15292ff23fea.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-7084c88b-af1e-4afd-9585-c67b79502dc5.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-2a8c1d94-30af-43fb-a9d8-670112041215.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-25fa75d3-ef86-47c8-94ec-b231be2ec36d.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-22e7c72d-10cc-4556-9a21-72ccab15ff13.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-609f546b-c322-49d9-a760-66d925e83dc4.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-73ce9810-af66-4240-91de-5721f0e3741d.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-b5a09600-47fb-47bc-8c18-d94578a24437.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-19e1a181-2c52-4cd1-8570-f16053d19a8a.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-7cce2888-a48b-417d-afa5-722da3648150.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-7070d50d-57dc-4fe2-bbb0-ca5cf90db5a1.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-be6530b7-a823-4bb6-9e67-d0534d5b2fa8.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-53f8cc6d-fc28-4253-b813-08b708237330.jpg",
                width: 1,
                height: 1,
                alt: "Nuestros Caniches"
            },
            {
                src: "/static/nuestros-caniches-phil-barca-001.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/nuestroscaniches-afb36261-8175-4548-abd0-cd35d9fd6cba.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/nuestroscaniches-f89a57af-8965-40db-a6f6-f1053b37808e.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/nuestroscaniches-2ecc7ca9-3712-4c98-9373-268b28de005b.jpg",
                width: 1,
                height: 1.7777777777777777,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/nuestros-caniches-001.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Nuestros caniches en la pelu"
                }
            },
            {
                src: "/static/nuestros-caniches-002.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Quira valor inter plata"
                }
            },
            {
                src: "/static/nuestros-caniches-003.jpeg",
                width: 1,
                height: 1.14,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Bygorri no te puedo retener"
                }
            },
            {
                src: "/static/nuestros-caniches-004.jpeg",
                width: 2,
                height: 2.66,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Edivette la niña"
                }
            },
            {
                src: "/static/nuestros-caniches-005.jpeg",
                width: 1,
                height: 1.78,
                alt: "Son Genet: Chihuahuas en Mallorca",
                overlay: {
                    title: "Bruno my puppies golden dream"
                }
            },
            {
                src: "/static/cachorros-disponibles-caniche-08a77f9e-39c5-410f-9298-58e0cbc13f84.jpg",
                width: 1,
                height: 1.7777777777777777,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-caniche-77880943-dd6d-4f71-b0ef-16129719f853.jpg",
                width: 1,
                height: 1.7777777777777777,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-caniche-4bfe2209-27ae-43e8-b9ee-bfc70d1ab0b4.jpg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Caniches Toy en Mallorca"
            },
            {
                src: "/static/cachorros-disponibles-caniche-d250d02c-3fd6-4e24-a8ef-d097951ae545.jpg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Caniches Toy en Mallorca"
            }
        ]
    },
    fotosencasa: {
        title: "",
        subTitle: "",
        custom: true,
        direction: "column",
        photos: [
            {
                src: "/static/cachorrosdisponibles-8acb7db0-b1ae-4cca-a86e-95a5d329f071.jpeg",
                width: 1,
                height: 0.47265625,
                alt: "Chihuahuas y Caniches Toy en casa"
            },
            {
                src: "/static/fotosencasa-09b82acf-d261-44ee-93dc-4251509239f8.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-7d7b611b-7661-48c3-9956-bf628a1a7c0d.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-4718242d-665e-4152-b45e-fc91b9771ff0.jpeg",
                width: 1,
                height: 1.7777777777777777,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-dd1bb4c1-c200-4e9a-9880-7191dbe8df5f.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-a97a76a4-d89f-4498-a90d-08277df5e795.jpeg",
                width: 1,
                height: 0.5678549093183239,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-e8230b89-4baf-48f2-9c79-ae8107701d97.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-661dbf74-9f8d-490c-851d-4f2507c475ca.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-04627031-e4a3-4acc-b8ae-6b790ddc1d35.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-3da4d1e2-91e9-4526-a360-b3e887e157f0.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-0e173046-e900-4076-917c-d14b788d6a02.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-4d1bc72e-5592-43a3-ab31-44131607ca12.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-fc16f225-cd57-4219-b9d1-743d3024bda2.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-72e045cc-c06a-4e66-8a41-0f0daf5307c3.jpeg",
                width: 1,
                height: 1,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-d516d052-4062-49a2-8fc4-83f2efcf2625.jpeg",
                width: 1,
                height: 0.473125,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-d6d02fb6-0c19-4e1c-a22b-c577493b81e0.jpeg",
                width: 1,
                height: 0.5625,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-8c102f39-f23e-49f5-8405-b8ea22eada6e.jpeg",
                width: 1,
                height: 1,
                alt: "Fotos en casa"
            },
            {
                src: "/static/fotosencasa-19622a23-db98-4477-99b2-d9d94564e04e.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Chihuahuas en sus nuevas casas"
            },
            {
                src: "/static/fotosencasa-b010670d-3247-45aa-98ba-567beb30548a.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Chihuahuas en sus nuevas casas"
            },
            {
                src: "/static/fotosencasa-a18749ee-3313-4940-93cf-2643b2edb9cc.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Chihuahuas en sus nuevas casas"
            },
            {
                src: "/static/fotosencasa-9731340d-7208-490d-b35a-f317eec32d70.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-3c6e8e98-6cab-45cf-a481-d29d830afdcc.jpeg",
                width: 1,
                height: 1.3342245989304813,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-7e789a30-c2b3-49c0-9f6f-a39d7e52cd83.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-d032f3dd-85da-4bd6-9e58-2447a71d62d4.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-5ded18af-dfcd-4638-a29e-1b089cd74d70.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-1b836a66-693f-4f0c-b4bd-a00fe3b65d17.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-f5606743-0eb3-47a5-95cd-517dfd8c2e60.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-38106de5-df0c-4a2d-a735-00216377b08c.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-952ab47b-4fd4-4eaf-a0d2-db6f454d0dca.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-2ea90cdf-af43-4652-9fff-3994bedf87fa.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-9c9c76d9-1009-4a96-9532-4c9fd38e990e.jpeg",
                width: 1,
                height: 0.75,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-afb50d1b-59c5-414c-b652-963ea26fddde.jpeg",
                width: 1,
                height: 1.3333333333333333,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            },
            {
                src: "/static/fotosencasa-aaba8227-c291-4679-9ab3-52de6ab55a79.jpeg",
                width: 1,
                height: 1.382146439317954,
                alt: "Son Genet: Chihuahuas en Mallorca | Caniches Toy en Mallorca"
            }
        ]
    }
};
